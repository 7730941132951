body {
  margin: 0;
  background-color: black;
  color: white;
  font-size: 7vw;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  letter-spacing: 0.0125em;
  text-transform: lowercase;
}

#root {
  padding: 0.75em 1em;
  pointer-events: none;
  user-select: none;
}

p {
  margin: 1em auto;
}
p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}
p > span {
  display: inline-block;
  width: 1em;
  text-align: center;
}
/*# sourceMappingURL=index.6f425b88.css.map */
